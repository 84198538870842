import FormManager from "../services/form-manager";

require("../polyfills");
require('../utilities');
require("../services/logging-manager");

import AbstractReactBehaviour from "../behaviours/abstract-react-behaviour";
import LazyLoad from "vanilla-lazyload";
import CookieManager from "../services/cookie-manager";
import CacheManager from "../services/cache-manager";

export default class Application  {
    env = null;
    initialized = false;

    constructor(config, enviroment = 'prod') {
        this.env = enviroment;
        this.formManager = new FormManager();
        this.cookieManager = new CookieManager();
        this.cacheManager = new CacheManager();

        this.init(config);

        window.app = this;  //register as global window attribute so methods can be accessed from the console.
        window.initApp = this.load; //create initApp to support legacy js.

        //Load app if document is ready, if not create an event listner to load app when content does finish.
        document.readyState !== "loading" ? window.app.load() : document.addEventListener("DOMContentLoaded",  window.app.load);
    }

    registerBehaviors = config => {
        let defaults = {};
        config = Object.assign(defaults, config);

        window.dataAttrBehaviours = config;
    };

    registerReactComponents = config => {
        let defaults = {};
        config = Object.assign(defaults, config);

        class ReactBehaviour extends AbstractReactBehaviour {
            getComponentMap = () => config;
        }

        if(!window.dataAttrBehaviours) window.dataAttrBehaviours = {};
        window.dataAttrBehaviours.ReactBehaviour = new ReactBehaviour();
    };

    registerLazyLoad = config => {
        let defaults = {
            elements_selector: ".lazyload",
            class_loading: "lazyload--loading",
            class_loaded: "lazyload--loaded",
            skip_invisible: false,
        };

        config = Object.assign(defaults, config);
        window.lazy = new LazyLoad(config);
    };

    loadDataAttrBehaviours = behaviours => {
        for (let key in behaviours) {
            behaviours[key].init();
        }


        if(this.env === 'dev') console.log('Loaded JS behaviours', behaviours); //@ignore
    };

    init = config => {
        let defaults = {
            behaviours: {},
            components: {},
            lazyLoad: {},
            //serviceWorker: {}
        };

        config = Object.assign(defaults, config);

        if(this.env === 'dev')  console.log('Initializing App', config); //@ignore
        if(!this.initialized) {
            //this.registerServiceWorker( config.serviceWorker);
            this.registerBehaviors(config.behaviours);
            this.registerReactComponents(config.components);
            this.registerLazyLoad(config.lazyLoad);

            this.initialized = true;
        }

    };

    load = () => {
        this.loadDataAttrBehaviours( window.dataAttrBehaviours );
        window.lazy.update();
        if(this.env === 'dev') console.log('Application Loaded'); //@ignore
    };
}
